<template>
  <div id="mainColumn1">
    <slideshow-module :slides="slides"></slideshow-module>
    <div
      class="column-row column-row-2"
      style="margin-bottom: 80px; height: 346px"
    >
      <div class="column column-large first last">
        <div class="column-top"></div>
        <div class="column-content">
          <div class="negative-row">
            <br />
            <div style="display: block; margin: 0px 10px 20px; color: #888888">
              <!-- UniverseTheGame picA4A2821C182E51E92971DC1E97AA5CFD.png -->
              <img
                style="padding-top: 40px"
                src="@/assets/the-game/buddies.png"
                align="right"
              />
              <h2 style="font-size: 24px">Adventure with your buddies</h2>
              Join forces with your friends to help save Imagination! Together
              you can explore every corner of the Universe uncovering the
              secrets to defeat the dark and mysterious Maelstrom! You’ll
              adventure around endless LEGO® worlds chatting with pals from all
              over Earth! Along the way, you can trade for the coolest bricks
              and accessories; race each other in custom LEGO racecars; compete
              in fun mini-games; and form teams to defeat fearsome creatures !
              <br />
              <br />
              <h2 style="font-size: 24px">Share your creativity</h2>
              Your LEGO Universe property is a creative world of your own where
              you can build anything you can imagine, then apply unique
              ‘behaviors’ to your creations to bring them to life! You can trade
              creations with other players, or let your pals visit your property
              to play in the world you’ve built! And, if you want to show off an
              awesome creation to a friend who isn’t logged into the game,
              simply send them an in-game Mail message to check out your
              property the next time they’re in LEGO Universe!
              <br />
              <br />
              <br />
              <span style="float: right; margin-left: 40px; width: 40%"
                ><router-link class="linkArrow" to="/parents/safety-tips"
                  >Safety Tips</router-link
                ><br />
                <router-link class="linkArrow" to="/parents/safety-faq"
                  >Safety Questions</router-link
                >
              </span>
              If you are interested in Safety and Chat related topics, why not
              visit our Parent Section? Here you can find answers to all your
              safety questions and more.<br />
              <br />
            </div>
          </div>
          <div class="clear"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Slideshow from "@/components/Slideshow.vue";

export default {
  data() {
    return {
      slides: [
        {
          // UniverseSlideShowItems/otherfiles/download6F8464931A85A90CF6C3D4B494CE49A4.swf
          img: require("@/assets/slideshow-items/connect-movie.jpg"),
          video: require("@/assets/slideshow-items/connect.mp4"),
          videoPos: "A",
          headline1: "In-Game Video",
          headline2: "Connect with Friends",
          text1:
            '<font color="#F2F2F2">Build, battle and explore with your friends in LEGO Universe. From team missions to chatting, learn about the many ways you can connect with other players in the game!</font>',
          //link: '<a href="http://universe.lego.com/en-us/media/default.aspx?section=141010&category=194313&contentID=213636">Watch the video!</a>',
          link: "/media/s/141010/c/194313/id/213636",
          linkText: "Watch the video!",
        },
      ],
    };
  },
  components: {
    Slideshow,
  },
};
</script>
